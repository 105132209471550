

function validateEmail(mail) {
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  {
    return (true);
  }
    // alert("You have entered an invalid email address!")
    return (false);
}


function validatePhone(phone) {
  const phoneRegex = /^(0\d{8}|\+373\d{8})$/;
  if (phoneRegex.test(phone))
   {
     return (true);
   }
     // alert("You have entered an invalid email address!")
     return (false);
 }


export function validateOrganization(organization){
  // console.log("ValidateOrganization: ", organization);
  let validation = true;

  let errors = {
    name: organization.name === "",
    idno: organization.idno === "",
    contact: organization.contact === "",
    contract: organization.contract === "",
    contactPhone: organization.contactPhone === "",
    contactEmail: organization.contactEmail === "",
    organizationPhone: organization.organizationPhone === "",
    organizationEmail: organization.organizationEmail === "",
  }

    const idnoRegex = /^\d+$/g;
    const nameRegex = /^[a-zA-Zа-яА-Я0-9 .'"-]{5,70}$/g;
    const contractRegex = /^.{3,40}/g;
    const contactRegex = /^[a-zA-Zа-яА-Я0-9 .-]{5,70}$/g;

  if (organization.name === "" || organization.idno === "" || organization.contact === "" ||
  organization.contract === "" || organization.contactPhone === "" || organization.contactEmail === "" || 
  organization.organizationEmail === "" || organization.organizationEmail === "") {
    validation = false;
  };
  if (organization.idno.length !== 13 || !idnoRegex.test(organization.idno)) {
    validation = false;
    errors.idno = true;
  };

  if (!organization.name.match(nameRegex)) {
    validation = false;
    errors.name = true;
  };

  if (!organization.contract.match(contractRegex)) {
    validation = false;
    errors.contract = true;
  };

  if (!organization.contact.match(contactRegex)) {
    validation = false;
    errors.contact = true;
  };

  if (!validatePhone(organization.contactPhone)){
    validation = false;
    errors.contactPhone = true;
  };

  if (!validateEmail(organization.contactEmail)){
    validation = false;
    errors.contactEmail = true;
  }

  if (!validatePhone(organization.organizationPhone)) {
    validation = false;
    errors.organizationPhone = true;
  }

  if (!validateEmail(organization.organizationEmail)) {
    validation = false;
    errors.organizationEmail = true;
  }

  // console.log("errors in validation", errors)
  return {
    validation: validation, 
    errors: errors
  };

}


export function validateCashRegister(cashRegister){
  // console.log("ValidateCashRegister: ", cashRegister);
  let validation = true;
  let errors = {
    address: cashRegister.address === '',
    model: cashRegister.model === '',
    domain: cashRegister.domain === '',
    kindOfActivity: cashRegister.kindOfActivity === '', 
    factory: cashRegister.factory === '', 
    number: cashRegister.number === '',
    key: cashRegister.key === ''
  };

  if (
    cashRegister.address === '' ||
    cashRegister.model === '' ||
    cashRegister.domain === '' ||
    cashRegister.kindOfActivity === '' ||
    cashRegister.factory === '' ||
    cashRegister.number === '' ||
    cashRegister.key === '') {
      validation = false;
  }

  if (!(/^\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(cashRegister.domain))){
    validation = false;
    errors.domain = true;
  }

  if (cashRegister.number.length < 10 || cashRegister.number.length > 12){
    validation = false;
    errors.number = true
  }

  return {
    validation: validation, 
    errors: errors
  };
}

export function validateUser(user){
  // console.log("validateUser: ", user);
  let validation = true;

  if (
    user.name === '' ||
    user.simpalsLogin === '' ||
    user.simpalsId === '' ||
    user.organizationId === '' ) {
      validation = false;
  }

  if (isNaN(user.organizationId)){
    validation = false;
  }

  return validation;
}