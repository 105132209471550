import './App.css';
import TopBar from './components/TopBar';
import AdminPanel from './components/AdminPanel';
import LoginPage from './components/LoginPage';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          
          <TopBar />

          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Routes>
            {/* <Route path="admin/*" element={<AdminPanel />} /> */}
            <Route path="/*" element={<AdminPanel />} />
            <Route path="login/" element={<LoginPage />} />
          </Routes>
        </div>
      </Router>


      
    </div>
  );
}

export default App;
