import React from 'react';

import Cookies from 'js-cookie';

import Box from '@mui/material/Box';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from "react-router-dom";
import { translation } from '../../services/translation/translation';
import RU from '../../services/translation/ru.json'

export default function Menu() {
  let navigate = useNavigate();
  const [colorsBG, setColorsBG] = React.useState({
    companies: '#6b6d78',
    // cashRegisters: '#313238',
    users: '#313238',
  });
  const [dict, setDict] = React.useState(RU);

  React.useEffect(() => {
    if (Cookies.get('simpalsid.lang')) {
      setDict(translation(Cookies.get('simpalsid.lang')));
    } else {
      setDict(translation("ru"));
    }
  }, [])


  const setBGcolor = (target) => {
    let newColors = {}
    Object.keys(colorsBG).map((key) => {
      if (key === target) {
        newColors[key] = '#6b6d78'
      } else {
        newColors[key] = '#313238'
      }
    })
    setColorsBG(newColors);
  }

  return (
    <Box sx={{backgroundColor: '#313238', height: '99%', width: '9%', position: 'fixed'}} >
      <MenuList sx={{marginTop: 4}}>

        <MenuItem 
          sx={{backgroundColor: colorsBG.companies}}
        >
          <ListItemText sx={{color: '#FFFFFF' }} 
          onClick={() => { setBGcolor('companies'); navigate("/");}}>{dict.organization}</ListItemText>
        </MenuItem>

        <MenuItem 
          sx={{backgroundColor: colorsBG.companies}}
        >
          <ListItemText sx={{color: '#FFFFFF' }} 
          onClick={() => { setBGcolor('taxes'); navigate("/taxes");}}>{dict.taxes_logs}</ListItemText>
        </MenuItem>

        {/* <MenuItem
        sx={{backgroundColor: colorsBG.cashRegisters}}
        >
          <ListItemText sx={{color: '#FFFFFF'}} 
          onClick={() => { setBGcolor('cashRegisters'); navigate("/admin/cash-registers");}}>Cash registers</ListItemText>
        </MenuItem> */}


      </MenuList>
  </Box>
  )
}
