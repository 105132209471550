import axios from "axios";
import Cookies from "js-cookie";

const basePath =
    process.env.NODE_ENV === "development"
        ? "http://127.0.0.1:8000/data"
        : // ? "https://dev-mev.id.md/data"
          "/data";

axios.defaults.withCredentials = true;

if (process.env.NODE_ENV === "development") {
    axios.defaults.headers.common["auth"] = Cookies.get("simpalsid.auth");
}

// POST request to GraphQL get all companyes
export function getOrganizations({ idno, name, skip, limit }) {
    let query = `
      query {
        getOrganizations(pagination: {
          skip: ${skip},
          limit: ${limit}
        }`;

    if (name) query += `, name: "${name}"`;
    if (idno) query += `, idno: "${idno}"`;

    query += `) {
        count
        data {
          id
          name
          contact
          contactPhone
          contactEmail
          organizationPhone
          organizationEmail
          contract
          idno
          cashRegisters {
            id
            model
            address
            kindOfActivity
            factory
            number
            key
            domain
          }
          cashRegistersCount
          watPay
        }
      }
    }`;

    // Perform the request
    const organization = axios({
        url: basePath,
        method: "post",
        data: {
            query,
        },
    })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.message;
        });

    return organization;
}

// POST request to GraphQL get companyes by id
export function getOrganizationById(companyId, skip = 0, limit = 20) {
    console.log(companyId, skip, limit);
    const organization = axios({
        url: basePath,
        method: "post",
        data: {
            query: `
            query {
              getOrganization(id: ${companyId}, crSkip: ${skip}, crLimit: ${limit}){
                id,
                name,
                idno,
                contact,
                contactEmail,
                contactPhone,
                organizationEmail,
                organizationPhone,
                contract,
                watPay,
                cashRegisters {
                  id,
                  model,
                  address,
                  kindOfActivity,
                  factory,
                  number,
                  key,
                  domain,
                },
                cashRegistersCount,
              }
            }
          `,
        },
    })
        .then((res) => {
            // console.log("get all receipts success");
            return res.data;
        })
        .catch((err) => {
            // console.log("get all receipts error");
            return err.message;
        });
    return organization;
}

// POST request to GraphQL create new organization
//TODO: fix wat pay
export function createNewOrganization(organization) {
    return axios({
        url: basePath,
        method: "post",
        data: {
            query: `
          mutation($input: OrganizationCreateInput!){
            organizationCreate(input: $input) {errors {message}}
          }
        `,
            variables: {
                input: {
                    name: organization.name,
                    contact: organization.contact,
                    contactPhone: organization.contactPhone,
                    contactEmail: organization.contactEmail,
                    organizationPhone: organization.organizationPhone,
                    organizationEmail: organization.organizationEmail,
                    contract: organization.contract,
                    idno: organization.idno,
                    watPay: organization.watPay ? 1 : 0,
                },
            },
        },
        // config: config,
    })
        .then((res) => {
            // console.log(res);
            return res;
        })
        .catch((err) => {
            return err.message;
        });
}

// POST request to GraphQL update organization
//TODO: fix wat pay
export function updateOrganization(organization) {
    return axios({
        url: basePath,
        method: "post",
        data: {
            query: `
          mutation($input: OrganizationUpdateInput!){
            organizationUpdate(input: $input) {errors {message}}
          }
        `,
            variables: {
                input: {
                    id: organization.id,
                    name: organization.name,
                    contact: organization.contact,
                    contactPhone: organization.contactPhone,
                    contactEmail: organization.contactEmail,
                    organizationPhone: organization.organizationPhone,
                    organizationEmail: organization.organizationEmail,
                    contract: organization.contract,
                    idno: organization.idno,
                    watPay: organization.watPay ? 1 : 0,
                },
            },
        },
        // config: config,
    })
        .then((res) => {
            // console.log(res);
            return res;
        })
        .catch((err) => {
            return err.message;
        });
}

// POST request to GraphQL create new CashRegister
export function createNewCashRegister(cashRegister, organizationId) {
    return axios({
        url: basePath,
        method: "post",
        data: {
            query: `
       mutation{
        cashRegisterCreate(
          input: {
            address: "${cashRegister.address}"
            kindOfActivity: "${cashRegister.kindOfActivity}"
            number: "${cashRegister.number}"
            key: "${cashRegister.key}"
            domain: "${cashRegister.domain}"
          },
          organizationId: ${organizationId}
        ){errors {message}}
      }`,
        },
        // config: config,
    })
        .then((res) => {
            // console.log(res);
            return res;
        })
        .catch((err) => {
            return err.message;
        });
}

// POST request to GraphQL update CashRegister
export function cashRegisterUpdate(cashRegister) {
    return axios({
        url: basePath,
        method: "post",
        data: {
            query: `
       mutation{
        cashRegisterUpdate(
          input: {
            id: ${cashRegister.id}
            address: "${cashRegister.address}"
            kindOfActivity: "${cashRegister.kindOfActivity}"
            number: "${cashRegister.number}"
            key: "${cashRegister.key}"
            domain: "${cashRegister.domain}"
          }
        ){errors {message}}
      }`,
        },
        // config: config,
    })
        .then((res) => {
            // console.log(res);
            return res;
        })
        .catch((err) => {
            return err.message;
        });
}

// POST request to GraphQL cash Register Api Key Refresh
export function cashRegisterApiKeyRefresh(cashId) {
    const organization = axios({
        url: basePath,
        method: "post",
        data: {
            query: `
       mutation{
        cashRegisterApiKeyRefresh(
          id: ${cashId},
        ){errors {message}}
      }`,
        },
    })
        .then((res) => {
            // console.log("get all receipts success");
            return res.data;
        })
        .catch((err) => {
            // console.log("get all receipts error");
            return err.message;
        });
    return organization;
}

// POST request to GraphQL get cash register by id
export function getCashById(orgId, cashId) {
    const organization = axios({
        url: basePath,
        method: "post",
        data: {
            query: `
         query{
            getOrganization(id: ${orgId}, cashRegisterId: ${cashId}){
              id,
              name,
              idno,
              contact,
              contactEmail,
              contactPhone,
              organizationEmail,
              organizationPhone,
              cashRegisters{
                id, model, address, kindOfActivity, factory, number,key,domain}
              }
          }`,
        },
    })
        .then((res) => {
            // console.log("get all receipts success");
            return res.data;
        })
        .catch((err) => {
            // console.log("get all receipts error");
            return err.message;
        });
    return organization;
}

// POST request to GraphQL get all users
export function getUsers() {
    const organization = axios({
        url: basePath,
        method: "post",
        data: {
            query: `
         query{
          getUsers{
            id, simpalsLogin, simpalsId, profiles{name,  organizationId, roles{id, organizationId,role}}
            }
        }`,
        },
    })
        .then((res) => {
            // console.log("get all receipts success");
            return res.data;
        })
        .catch((err) => {
            // console.log("get all receipts error");
            return err.message;
        });
    return organization;
}

// POST request to GraphQL get all users
export function getUserById(userId) {
    const organization = axios({
        url: basePath,
        method: "post",
        data: {
            query: `
       query{
        getUser(id: ${userId}){
          id, simpalsLogin, simpalsId, profiles{name,  organizationId, roles{id, organizationId,role}}
          }
      }`,
        },
    })
        .then((res) => {
            // console.log("get all receipts success");
            return res.data;
        })
        .catch((err) => {
            // console.log("get all receipts error");
            return err.message;
        });
    return organization;
}

// POST request to GraphQL create user
export function createUser(user) {
    const organization = axios({
        url: basePath,
        method: "post",
        data: {
            query: `
       mutation{
        userCreate(
          input: {
            name: "${user.name}",
            simpalsId: "${user.simpalsId}"
            simpalsLogin: "${user.simpalsLogin}"
            organizationId: ${user.organizationId}
          },
        ){errors {message}}
      }`,
        },
    })
        .then((res) => {
            // console.log("get all receipts success");
            return res.data;
        })
        .catch((err) => {
            // console.log("get all receipts error");
            return err.message;
        });
    return organization;
}

// export function getSimpalsIdBySimpalsLogin(simpalsLogin){
//   console.log(simpalsLogin)
//   const request = axios({
//     url: `http://accounts.service.simpals:18333/v1.0/users?login=${simpalsLogin}`,
//     method: 'GET',
//     headers: {
//       'Cache-Control': 'no-cache',
//       'Host': 'accounts.service.simpals:18333',
//       'Connection': 'keep-alive',
//       'Content-Type' : 'application/json',
//     },
//   })
//   return request;
// }

// // POST request to GraphQL create new z report
// export function createNewReport(cashId, type) {
//     return axios({
//         url: basePath,
//         method: 'post',
//         data: {
//          query: `
//          mutation{
//             generateReport(cashRegisterId:${cashId} compartiment:ACPS, type:${type}){
//               errors {
//                 message
//               }
//             }
//           }`
//         },
//         // config: config,
//     })
//     .then(res => {
//         // console.log(res);
//         return res;
//     })
//     .catch(err => {
//         return err.message;
//     });
// }

// // POST request to GraphQL get current organization
// export function getCurrentOrganization() {

//     // let tempCookie = Cookies.get('simpalsid.auth');
//     // console.log(tempCookie);
//     // Cookies.remove('simpalsid.auth');
//     const organization = axios({
//         url: basePath,
//         method: 'post',
//         data: {
//          query: `
//          query {
//             getCurrentInfo{organization{
//               name, idno,
//               cashRegisters{
//                 id, model,address,factory,number,key
//               }
//             }}
//           }`
//         },
//     })
//     .then(res => {
//         // console.log("get organization success");
//         return res.data
//     })
//     .catch(err => {
//         // console.log("get organization error");
//         return err.message
//     });
//     return organization;
// }

// // POST request to GraphQL get current user
// export function getCurrentUser() {
//   const organization = axios({
//       url: basePath,
//       method: 'post',
//       data: {
//        query: `
//        query {
//         getCurrentInfo{account{
//           userId,login
//         }}
//       }`
//       },
//   })
//   .then(res => {
//       // console.log("get user success");
//       return res.data
//   })
//   .catch(err => {
//       // console.log("get user error");
//       return err.message
//   });
//   return organization;
// }

// // POST request to GraphQL get all reports
// export function getAllMyReports() {
//     const organization = axios({
//         url: basePath,
//         method: 'post',
//         data: {
//          query: `
//          query{
//           getReports(sort: {column: "_id", direction: DESC}){
//             count,
//             data{
//               id,
//               soapData{
//                 id, type, report, datetime,
//                 reports{
//                   daily{total}
//                   year{total}
//                 }
//               },
//               status,
//             },
//           }
//         }`
//         },
//     })
//     .then(res => {
//         // console.log("get getReports success");
//         return res.data
//     })
//     .catch(err => {
//         // console.log("get getReports error");
//         return err.message
//     });
//     return organization;
// }

// // POST request to GraphQL get all receipts
// export function getAllMyReceipts() {
//   const organization = axios({
//       url: basePath,
//       method: 'post',
//       data: {
//        query: `
//        query{
//         getFiscalReceipts(sort: {column: "_id", direction: DESC}){
//           count,
//           data{
//             id,
//             soapData{
//               id,receipt,report, datetime, activity{total, payments{field{type}}}
//             },
//             status,
//           }
//         }
//       }`
//       },
//   })
//   .then(res => {
//       // console.log("get all receipts success");
//       return res.data
//   })
//   .catch(err => {
//       // console.log("get all receipts error");
//       return err.message
//   });
//   return organization;
// }

// // POST request to GraphQL  Get Receipt by id
// export function getReceiptById(id) {
//   const organization = axios({
//       url: basePath,
//       method: 'post',
//       data: {
//        query: `
//        query {
//         getFiscalReceipt(id: ${id}){
//         id,status, compartiment,
//         soapData{
//           id, report, receipt, datetime, card{
//             name, address, idnx, factory, number
//           },
//           activity{
//             total, taxes{field{code, percent,rate}},
//             payments{field{type}},
//             activities{field{
//               name, amount, price,
//               tax{code, percent,rate}, cost}}
//           }
//         }
//       }
//       }`
//       },
//   })
//   .then(res => {
//       // console.log("get receipt success");
//       return res.data
//   })
//   .catch(err => {
//       // console.log("get receipt error");
//       return err.message
//   });
//   return organization;
// }

// // POST request to GraphQL  Get report by id
// export function getReportById(id) {
//   const organization = axios({
//       url: basePath,
//       method: 'post',
//       data: {
//        query: `
//        query {
//         getReport(id: ${id}){
//         id,status, compartiment,
//         soapData{
//           id, report, type, datetime, card{
//             name, address, idnx, factory, number
//           },
//           reports{
//             daily{
//               taxes{
//                 field{
//                   code, percent, rate, total
//                 }
//               },
//               untaxed, total, tax,
//               receipts{
//                 field{
//                   type, count
//                 }
//               },
//               payments{
//                 field{type, sum}
//               },
//               totals{introduced,issued,balance}
//             },
//             year{total, tax}
//           }
//         }
//       }
//       }`
//       },
//   })
//   .then(res => {
//       // console.log("get receipt success");
//       return res.data
//   })
//   .catch(err => {
//       // console.log("get receipt error");
//       return err.message
//   });
//   return organization;
// }

// // POST request to GraphQL get all service receipts
// export function getAllMyServiceReceipts() {
//   const organization = axios({
//       url: basePath,
//       method: 'post',
//       data: {
//        query: `
//        query{
//         getServiceReceipts(sort: {column: "_id", direction: DESC}){
//           count,
//           data{
//             id,
//             soapData{
//               id,report, datetime,
//               activity{total, type, name}
//             },
//             status,
//           }
//         }
//       }`
//       },
//   })
//   .then(res => {
//       // console.log("get all receipts success");
//       return res.data
//   })
//   .catch(err => {
//       // console.log("get all receipts error");
//       return err.message
//   });
//   return organization;
// }

// // POST request to GraphQL  Get Service Receipt by id
// export function getServiceReceiptById(id) {
//   const organization = axios({
//       url: basePath,
//       method: 'post',
//       data: {
//        query: `
//        query {
//         getServiceReceipt(id: ${id}){
//         id,status, compartiment,
//         soapData{
//           id, report, datetime, card{
//             name, address, idnx, factory, number
//           },
//           activity{
//             total, name, type
//           }
//         }
//       }
//       }`
//       },
//   })
//   .then(res => {
//       // console.log("get receipt success");
//       return res.data
//   })
//   .catch(err => {
//       // console.log("get receipt error");
//       return err.message
//   });
//   return organization;
// }
