import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Popper from "@mui/material/Popper";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import { login } from "../services/requests/ApiRequests";

const theme = createTheme();

export default function SignIn() {
    let navigate = useNavigate();

    const authCookie = Cookies.get("auth");
    if (!!authCookie) {
        Cookies.remove("simpalsid.lang");
        navigate("/");
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if (data.get("login") != "" && data.get("password") != "") {
            let credentials = {
                login: data.get("login"),
                pass: data.get("password"),
            };

            login(credentials)
                .then((data) => {
                    // console.log(data);
                    if (data.data.success == true) {
                        navigate("/");
                    }
                })
                .catch((err) => {
                    // console.log(err.response.data);
                    alert(err.response.data);
                });
        } else {
            console.log("wrong data");
        }
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popper" : undefined;

    return (
        <Box
            sx={{
                width: "100%",
                height: "90%",
                position: "absolute",
                marginTop: 9,
            }}
        >
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box
                            component="form"
                            onSubmit={handleSubmit}
                            noValidate
                            sx={{ mt: 1 }}
                        >
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="login"
                                label="Login"
                                name="login"
                                autoComplete="login"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value="remember"
                                        color="primary"
                                    />
                                }
                                label="Remember me"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Sign In
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link variant="body2" onClick={handleClick}>
                                        Forgot login or password?
                                    </Link>
                                </Grid>
                            </Grid>
                            <Popper id={id} open={open} anchorEl={anchorEl}>
                                <Box
                                    sx={{
                                        border: 1,
                                        p: 1,
                                        bgcolor: "background.paper",
                                    }}
                                >
                                    You need to contact Simpals to get a login
                                    and password.
                                </Box>
                            </Popper>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </Box>
    );
}
