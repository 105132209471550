import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import FormHelperText from '@mui/material/FormHelperText';

import Cookies from 'js-cookie';

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { validateOrganization } from '../../services/Validations';
import { getOrganizationById } from '../../services/requests/GraphRequests';
import { updateOrganization } from '../../services/requests/GraphRequests';
import { translation } from '../../services/translation/translation';
import RU from '../../services/translation/ru.json'

export default function EditCompany() {
  let navigate = useNavigate();
  let params = useParams();
  const [loading, setLoading] = React.useState(true);
  const [company, setCompany] = React.useState({});
  const [dict, setDict] = React.useState(RU);
  const [formValidationError, setFormValidationError] = React.useState({
    name: false,
    idno: false,
    contact: false,
    contract: false,
    contactPhone: false,
    contactEmail: false,
    organizationPhone: false,
    organizationEmail: false,
  });



  React.useEffect(() => {
    getOrganizationById(params.companyId)
    .then((data) => {
      // console.log("data: ", data.data.getOrganization);
      setCompany(data.data.getOrganization);
      setLoading(false);

    });
    if (Cookies.get('simpalsid.lang')) {
      setDict(translation(Cookies.get('simpalsid.lang')));
    } else {
      setDict(translation("ru"));
    }
  }, [])

  let [readyToSave, setReadyToSave] = React.useState(false);
  let [thereAreChanges, setThereAreChanges] = React.useState(false);

  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [error, setError] = React.useState({
      status: false,
      msg: "Error"
  });

  React.useEffect(() => {
    if(openSuccess){
      setTimeout(function () {
        navigate(`/company/${company.id}`);
    }, 1500);
    }
  }, [openSuccess, setOpenSuccess]);

  React.useEffect(() => {
      if (thereAreChanges) {
        let validateResult = validateOrganization(company)

        setReadyToSave(validateResult.validation);
        setFormValidationError(validateResult.errors)
      }
  }, [company, setCompany]);


  const handleChange = (event) => {
    setCompany({
        ...company,
        [event.target.name]: event.target.value,
    });
    setThereAreChanges(true);
  };

  const handleChangeWatPay = (event) => {
    setCompany({
      ...company,
      watPay: !company.watPay
    });
    setThereAreChanges(true)
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
    setError({
      status: false,
      msg: "Error"
    });
  };


  function saveOrganization() {
    updateOrganization(company)
    .then((data) => {
      if(data.data.data.organizationUpdate !== null){
        if (data.data.data.organizationUpdate.errors.length === 0) {                
          setOpenSuccess(true);
        }
      } else {
        let msgError = "There are errors while receipt generation"
          if (data.data.errors.length > 0){
            msgError = data.data.errors[0].message
          }
          setError({
              status: true,
              msg:  msgError,
          });
      }

    })
    // console.log("saved");
    
  }


  if (loading) {
    return (
      <div align='center'>
          <CircularProgress />
      </div>
    )
  }
  // console.log(company);
  return (
    <Box sx={{ flexGrow: 1, marginTop: 3, marginLeft: 10, marginBottom: 10 }}>
      <Grid container sx={{ marginTop: 2 }} spacing={4} align='left'>
        <Grid item xs={6}>
          <Typography sx={{ fontSize: 24}} color="pimary" gutterBottom>
            {dict.edit_org} №{company.id}
          </Typography>
          <Button variant="text" color="secondary" onClick={() => { navigate(`/company/${company.id}`);}}>&#60; {dict.go_back}</Button>
        </Grid>
        <Grid item xs={5} align='right'>
          <Button variant="outlined"
            disabled={!readyToSave}
            onClick={() => {saveOrganization();}}
          >
              {dict.save_new_org}
          </Button>
        </Grid>

        <Grid container sx={{ marginTop: 2 }} spacing={4} align='left'>
        <Grid item xs={5}>

          <FormControl  fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="component-simple">{dict.name}</InputLabel>
              <Input id="component-simple" value={company.name} onChange={handleChange} name='name' 
              aria-describedby="standard-helper-text"
              inputProps={{
              'aria-label': 'text',
            }}
            error={formValidationError.name}
          />
            <FormHelperText id="standard-helper-text">{dict.must_not_empty}</FormHelperText>
          </FormControl>
          <FormControl  fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="component-simple">{dict.contact}</InputLabel>
              <Input id="component-simple" value={company.contact} onChange={handleChange} name='contact' 
              aria-describedby="standard-contact-helper-text"
              inputProps={{
              'aria-label': 'contact',
            }}
            error={formValidationError.contact}
          />
            <FormHelperText id="standard-contact-helper-text">{dict.must_not_empty}</FormHelperText>
          </FormControl>
          <FormControl  fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="component-simple">{dict.contract_number}</InputLabel>
              <Input id="component-simple" value={company.contract} onChange={handleChange} name='contract' 
              aria-describedby="standard-contract-helper-text"
              inputProps={{
              'aria-label': 'contract',
            }}
            error={formValidationError.contract}
          />
            <FormHelperText id="standard-contract-helper-text">{dict.must_not_empty}</FormHelperText>
          </FormControl>
          <FormControlLabel fullWidth sx={{ m: 1 }} variant="standard" control={
              <Switch 
                checked={company.watPay}
                onChange={handleChangeWatPay}
              />} name='watPay' label={dict.watpay}
            />
        </Grid>
        <Grid item xs={6}>
          <FormControl  fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="component-simple">{dict.idno}</InputLabel>
              <Input id="component-simple" value={company.idno} onChange={handleChange} name='idno' 
              aria-describedby="standard-idno-helper-text"
              inputProps={{
              'aria-label': 'idno',
            }}
            error={formValidationError.idno}
          />
            <FormHelperText id="standard-idno-helper-text">{dict.helper_text_idno}</FormHelperText>
          </FormControl>
          <FormControl  fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="component-simple">{dict.phone_number}</InputLabel>
              <Input id="component-simple" value={company.contactPhone} onChange={handleChange} name='contactPhone' 
              aria-describedby="standard-phone-helper-text"
              inputProps={{
              'aria-label': 'phone',
            }}
            error={formValidationError.contactPhone}
          />
            <FormHelperText id="standard-phone-helper-text">{dict.helper_text_phone}</FormHelperText>
          </FormControl>
          <FormControl  fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="component-simple">{dict.email}</InputLabel>
              <Input id="component-simple" value={company.contactEmail} onChange={handleChange} name='contactEmail' 
              aria-describedby="standard-email-helper-text"
              inputProps={{
              'aria-label': 'email',
            }}
            error={formValidationError.contactEmail}
          />
            <FormHelperText id="standard-email-helper-text">{dict.helper_text_right_email}</FormHelperText>
          </FormControl>
          <FormControl  fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="component-simple">{dict.organization_phone}</InputLabel>
              <Input id="component-simple" value={company.organizationPhone} onChange={handleChange} name='organizationPhone' 
              aria-describedby="standard-phone-helper-text"
              inputProps={{
              'aria-label': 'phone',
            }}
            error={formValidationError.organizationPhone}
          />
            <FormHelperText id="standard-phone-helper-text">{dict.helper_text_phone}</FormHelperText>
          </FormControl>
          <FormControl  fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="component-simple">{dict.organization_email}</InputLabel>
              <Input id="component-simple" value={company.organizationEmail} onChange={handleChange} name='organizationEmail' 
              aria-describedby="standard-email-helper-text"
              inputProps={{
              'aria-label': 'email',
            }}
            error={formValidationError.organizationEmail}
          />
            <FormHelperText id="standard-email-helper-text">{dict.helper_text_right_email}</FormHelperText>
          </FormControl>
          
        </Grid>



      </Grid>

      </Grid>
      <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
              Organization saved!
          </Alert>
      </Snackbar>
      <Snackbar open={error.status} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
              {error.msg}
          </Alert>
      </Snackbar>
    </Box>
  )
}
