import React from "react";

import Cookies from "js-cookie";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import FormHelperText from "@mui/material/FormHelperText";

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { getOrganizationById } from "../../services/requests/GraphRequests";
import { createNewCashRegister } from "../../services/requests/GraphRequests";
import { validateCashRegister } from "../../services/Validations";
import { translation } from "../../services/translation/translation";
import RU from "../../services/translation/ru.json";

export default function AddCashRegister() {
    let navigate = useNavigate();
    let params = useParams();
    const { companyId = "" } = params || {};

    const [loading, setLoading] = React.useState(true);
    const [company, setCompany] = React.useState({});
    const [dict, setDict] = React.useState(RU);

    const [formValidationError, setFormValidationError] = React.useState({
        address: true,
        model: true,
        domain: true,
        kindOfActivity: true,
        factory: true,
        number: true,
        key: true,
    });

    React.useEffect(() => {
        getOrganizationById(companyId).then((data) => {
            // console.log("data: ", data.data.getOrganization);
            setCompany(data.data.getOrganization);
            setLoading(false);
        });
        if (Cookies.get("simpalsid.lang")) {
            setDict(translation(Cookies.get("simpalsid.lang")));
        } else {
            setDict(translation("ru"));
        }
    }, [companyId]);

    let [cashRegister, setCashRegister] = React.useState({
        address: "",
        domain: "",
        kindOfActivity: "",
        number: "",
        key: "",
    });

    let [readyToSave, setReadyToSave] = React.useState(false);

    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [error, setError] = React.useState({
        status: false,
        msg: "Error",
    });

    React.useEffect(() => {
        let validateResult = validateCashRegister(cashRegister);
        setReadyToSave(validateResult.validation);
        setFormValidationError(validateResult.errors);
    }, [cashRegister, setCashRegister]);

    React.useEffect(() => {
        if (openSuccess) {
            setTimeout(function () {
                navigate(`/company/${company.id}`);
            }, 3500);
        }
    }, [company.id, navigate, openSuccess, setOpenSuccess]);

    const handleChange = (event) => {
        setCashRegister({
            ...cashRegister,
            [event.target.name]: event.target.value,
        });
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenSuccess(false);
        setError({
            status: false,
            msg: "Error",
        });
    };

    function saveCashRegister() {
        createNewCashRegister(cashRegister, company.id).then((data) => {
            if (data.data.data.cashRegisterCreate !== null) {
                if (data.data.data.cashRegisterCreate.errors.length === 0) {
                    setOpenSuccess(true);
                }
            } else {
                let msgError = "There are errors while receipt generation";
                if (data.data.errors.length > 0) {
                    msgError = data.data.errors[0].message;
                }
                setError({
                    status: true,
                    msg: msgError,
                });
            }
        });
    }

    if (loading) {
        return (
            <div align="center">
                <CircularProgress />
            </div>
        );
    }

    return (
        <Box
            sx={{ flexGrow: 1, marginTop: 3, marginLeft: 10, marginBottom: 10 }}
        >
            <Grid container sx={{ marginTop: 2 }} spacing={4} align="left">
                <Grid item xs={7}>
                    <Typography
                        sx={{ fontSize: 24 }}
                        color="pimary"
                        gutterBottom
                    >
                        {dict.add_cash_register_for_organization} №{company.id}
                    </Typography>
                    <Button
                        variant="text"
                        color="secondary"
                        onClick={() => {
                            navigate(`/company/${company.id}`);
                        }}
                    >
                        &#60; {dict.go_back}
                    </Button>
                </Grid>
                <Grid item xs={4} align="right">
                    <Button
                        variant="outlined"
                        disabled={!readyToSave}
                        onClick={() => {
                            saveCashRegister();
                        }}
                    >
                        {dict.save_cash_register}
                    </Button>
                </Grid>
                {/* Organization info */}
                <Grid container item xs={12} spacing={1} align="left">
                    <Grid item xs={6}>
                        <Grid container spacing={1} align="left">
                            <Grid item xs={4}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    {dict.idno}:
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="pimary"
                                    gutterBottom
                                >
                                    {company.idno}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    {dict.organization_name}:
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="pimary"
                                    gutterBottom
                                >
                                    {company.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    {dict.contract_number}:
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="pimary"
                                    gutterBottom
                                >
                                    {company.contract}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <Grid container spacing={1} align="left">
                            <Grid item xs={4}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    {dict.contact_person}:
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="pimary"
                                    gutterBottom
                                >
                                    {company.contact}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    {dict.contact_person_phone}:
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="pimary"
                                    gutterBottom
                                >
                                    {company.contactPhone}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    {dict.contact_person_email}:
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="pimary"
                                    gutterBottom
                                >
                                    {company.contactEmail}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    {dict.organization_phone}:
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="pimary"
                                    gutterBottom
                                >
                                    {company.organizationPhone}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    {dict.organization_email}:
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="pimary"
                                    gutterBottom
                                >
                                    {company.organizationEmail}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container sx={{ marginTop: 2 }} spacing={4} align="left">
                <Grid item xs={5}>
                    <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                        <InputLabel htmlFor="component-simple">
                            {dict.address}
                        </InputLabel>
                        <Input
                            id="component-simple"
                            value={cashRegister.address}
                            onChange={handleChange}
                            name="address"
                            aria-describedby="standard-helper-text"
                            inputProps={{
                                "aria-label": "text",
                            }}
                            error={formValidationError.address}
                        />
                        <FormHelperText id="standard-helper-text">
                            {dict.address_sale_point}
                        </FormHelperText>
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                        <InputLabel htmlFor="component-simple">
                            {dict.domain}
                        </InputLabel>
                        <Input
                            id="component-simple"
                            value={cashRegister.domain}
                            onChange={handleChange}
                            name="domain"
                            aria-describedby="standard-helper-text"
                            inputProps={{
                                "aria-label": "text",
                            }}
                            error={formValidationError.domain}
                        />
                        <FormHelperText id="standard-helper-text">
                            {dict.dmn_must_name}
                        </FormHelperText>
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                        <InputLabel htmlFor="component-simple">
                            {dict.kind_activity}
                        </InputLabel>
                        <Input
                            id="component-simple"
                            value={cashRegister.kindOfActivity}
                            onChange={handleChange}
                            name="kindOfActivity"
                            aria-describedby="standard-helper-text"
                            inputProps={{
                                "aria-label": "text",
                            }}
                            error={formValidationError.kindOfActivity}
                        />
                        <FormHelperText id="standard-helper-text">
                            {dict.must_not_empty}
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                        <InputLabel htmlFor="component-simple">
                            {dict.registration_number}
                        </InputLabel>
                        <Input
                            id="component-simple"
                            value={cashRegister.number}
                            onChange={handleChange}
                            name="number"
                            aria-describedby="standard-helper-text"
                            inputProps={{
                                "aria-label": "text",
                            }}
                            error={formValidationError.number}
                        />
                        <FormHelperText id="standard-helper-text">
                            {dict.registration_number} MEV
                        </FormHelperText>
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                        <InputLabel htmlFor="component-simple">
                            {dict.key}
                        </InputLabel>
                        <Input
                            id="component-simple"
                            value={cashRegister.key}
                            onChange={handleChange}
                            name="key"
                            aria-describedby="standard-helper-text"
                            inputProps={{
                                "aria-label": "text",
                            }}
                            error={formValidationError.key}
                        />
                        <FormHelperText id="standard-helper-text">
                            {dict.must_not_empty}
                        </FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
            <Snackbar
                open={openSuccess}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity="success"
                    sx={{ width: "100%" }}
                >
                    Cash register created!
                </Alert>
            </Snackbar>
            <Snackbar
                open={error.status}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    {error.msg}
                </Alert>
            </Snackbar>
        </Box>
    );
}
