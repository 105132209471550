import RU from "./ru.json";
import RO from "./ro.json";
import EN from "./en.json";



export function translation(lang) {

    let dict = {};
    if (lang === "ro") {
        // console.log(RO);
        dict = RO;
    } else if (lang === "en") {
        // console.log(EN);
        dict = EN;
    } else {
        // console.log(RU);
        dict = RU;
    }

    return dict;
}