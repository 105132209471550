import React from 'react';

import Cookies from 'js-cookie';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import FormHelperText from '@mui/material/FormHelperText';

import { validateOrganization } from '../../services/Validations';
import { createNewOrganization } from '../../services/requests/GraphRequests';
import { translation } from '../../services/translation/translation';
import RU from '../../services/translation/ru.json'

import { useNavigate } from "react-router-dom";

export default function NewCompany() {
  let navigate = useNavigate();
  const [dict, setDict] = React.useState(RU);
  let [organization, setOrganization] = React.useState({
    name: '',
    idno: '',
    contactPhone: '',
    contactEmail: '',
    contact: '',
    contract: '',
    organizationPhone: '',
    organizationEmail: '',
    watPay: false,
  });

  const [readyToSave, setReadyToSave] = React.useState(false);

  const [formValidationError, setFormValidationError] = React.useState({
    name: true,
    idno: true,
    contact: true,
    contract: true,
    contactPhone: true,
    contactEmail: true,
    organizationPhone: true,
    organizationEmail: true,
  });

  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [error, setError] = React.useState({
      status: false,
      msg: "Error"
  });

  React.useEffect(() => {
    if (Cookies.get('simpalsid.lang')) {
      setDict(translation(Cookies.get('simpalsid.lang')));
    } else {
      setDict(translation("ru"));
    }
  }, []);

  React.useEffect(() => {
    let validateResult = validateOrganization(organization)

    setReadyToSave(validateResult.validation);
    setFormValidationError(validateResult.errors)
  }, [organization, setOrganization]);

  React.useEffect(() => {
    if(openSuccess){
      setTimeout(function () {
        navigate("/");
    }, 3500);
    }
  }, [openSuccess, setOpenSuccess]);

  const handleChange = (event) => {
    setOrganization({
        ...organization,
        [event.target.name]: event.target.value,
    });
  };

  const handleChangeWatPay = (event) => {
    setOrganization({
      ...organization,
      watPay: !organization.watPay
    })
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
    setError({
      status: false,
      msg: "Error"
    });
  };

  function saveOrganization() {
    createNewOrganization(organization)
    .then((data) => {
      if(data.data.data.organizationCreate !== null){
        if (data.data.data.organizationCreate.errors.length === 0) {                
          setOpenSuccess(true);
        }
      } else {
        let msgError = "There are errors while receipt generation"
          if (data.data.errors.length > 0){
            msgError = data.data.errors[0].message
          }
          setError({
              status: true,
              msg:  msgError,
          });
      }

    })
    
  }

  // console.log(organization);
  return (
    <Box sx={{ flexGrow: 1, marginTop: 3, marginLeft: 10, marginBottom: 10 }}>
      <Grid container sx={{ marginTop: 2 }} spacing={4} align='left'>
        <Grid item xs={6}>
          <Typography sx={{ fontSize: 24}} color="pimary" gutterBottom>
            {dict.add_new_org}
          </Typography>
        </Grid>
        <Grid item xs={5} align='right'>
          <Button variant="outlined"
          disabled={!readyToSave}
          onClick={() => {saveOrganization();}} 
          >
              {dict.save_new_org}
          </Button>
        </Grid>

        <Grid container sx={{ marginTop: 2 }} spacing={4} align='left'>
          <Grid item xs={5}>
            <FormControl  fullWidth sx={{ m: 1 }} variant="standard">
                <InputLabel htmlFor="component-simple">{dict.idno}*</InputLabel>
                <Input id="component-simple" value={organization.idno} onChange={handleChange} name='idno' 
                aria-describedby="standard-idno-helper-text"
                inputProps={{
                'aria-label': 'idno',
                }}
                error={formValidationError.idno}
                />
                <FormHelperText id="standard-idno-helper-text">{dict.fiscal_code_organization}</FormHelperText>
            </FormControl>
            <FormControl  fullWidth sx={{ m: 1 }} variant="standard">
                <InputLabel htmlFor="component-simple">{dict.organization_name}*</InputLabel>
                <Input id="component-simple" value={organization.name} onChange={handleChange} name='name' 
                  aria-describedby="standard-name-helper-text"
                  inputProps={{
                  'aria-label': 'name',
                  }}
                  error={formValidationError.name}
                />
                <FormHelperText id="standard-name-helper-text">{dict.organization_jur_name}</FormHelperText>
            </FormControl>
            <FormControl  fullWidth sx={{ m: 1 }} variant="standard">
                <InputLabel htmlFor="component-simple">{dict.contract_number}*</InputLabel>
                <Input id="component-simple" value={organization.contract} onChange={handleChange} name='contract' 
                  aria-describedby="standard-contract-helper-text"
                  inputProps={{
                  'aria-label': 'contract',
                  }}
                  error={formValidationError.contract}
                />
                <FormHelperText id="standard-contract-helper-text">{dict.ekassa_service_contract_number}</FormHelperText>
            </FormControl>
            <FormControl  fullWidth sx={{ m: 1 }} variant="standard">
                <InputLabel htmlFor="component-simple">{dict.contact_person}*</InputLabel>
                <Input id="component-simple" value={organization.contact} onChange={handleChange} name='contact' 
                aria-describedby="standard-contact-helper-text"
                inputProps={{
                'aria-label': 'contact',
                }}
                error={formValidationError.contact}
                />
                <FormHelperText id="standard-contact-helper-text">{dict.contact_person_fio}</FormHelperText>
            </FormControl>
            <FormControlLabel fullWidth sx={{ m: 1 }} variant="standard" control={
              <Switch 
                checked={organization.watPay}
                onChange={handleChangeWatPay}
              />} name='watPay' label={dict.watpay}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl  fullWidth sx={{ m: 1 }} variant="standard">
                <InputLabel htmlFor="component-simple">{dict.phone_number}*</InputLabel>
                <Input id="component-simple" value={organization.contactPhone} onChange={handleChange} name='contactPhone' 
                  aria-describedby="standard-phone-helper-text"
                  inputProps={{
                  'aria-label': 'phone',
                  }}
                  error={formValidationError.contactPhone}
                />
                <FormHelperText id="standard-phone-helper-text">{dict.contact_person_phone_number}</FormHelperText>
            </FormControl>
            <FormControl  fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="component-simple">{dict.email}*</InputLabel>
              <Input id="component-simple" value={organization.contactEmail} onChange={handleChange} name='contactEmail'
                aria-describedby="standard-email-helper-text"
                inputProps={{
                'aria-label': 'email',
                }}
                error={formValidationError.contactEmail}
              />
              <FormHelperText id="standard-email-helper-text">{dict.helper_contact_person_email}</FormHelperText>
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <InputLabel htmlFor="component-simple">
                    {dict.organization_phone}
                </InputLabel>
                <Input
                    id="component-simple"
                    value={organization.organizationPhone}
                    onChange={handleChange}
                    name="organizationPhone"
                    aria-describedby="standard-phone-helper-text"
                    inputProps={{
                        "aria-label": "phone",
                    }}
                    error={formValidationError.organizationPhone}
                />
                <FormHelperText id="standard-phone-helper-text">{dict.additional_organization_phone}</FormHelperText>
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <InputLabel htmlFor="component-simple">
                    {dict.organization_email}
                </InputLabel>
                <Input
                    id="component-simple"
                    value={organization.organizationEmail}
                    onChange={handleChange}
                    name="organizationEmail"
                    aria-describedby="standard-email-helper-text"
                    inputProps={{
                        "aria-label": "email",
                    }}
                    error={formValidationError.organizationEmail}
                />
                <FormHelperText id="standard-email-helper-text">{dict.additional_organization_email}</FormHelperText>
            </FormControl>
            </Grid>
        </Grid>
        <Grid item xs={12} align="left" sx={{ marginTop: 2 }}>
            {dict.note_mandatory_fields}
        </Grid>
      </Grid>
      <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
              Organization created!
          </Alert>
      </Snackbar>
      <Snackbar open={error.status} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
              {error.msg}
          </Alert>
      </Snackbar>
    </Box>
  )
}
